<template>
    <div>
        <label class="popup-add-object__label"
               for="objectFiles">{{params.label}}</label>
        <input id="objectFiles"
               multiple
               ref="objectFiles"
               type="file"
               v-on:change="addFiles">
        <Row class="popup-add-object__previews-files"
             wrap>
            <div :key="index"
                 class="popup-add-object__preview-file"
                 v-for="(preview, index) in previews">
                <img :src="preview"
                     class="popup-add-object__file-image"/>
                <Icon class="popup-add-object__file-remove"
                      v-on:click.native="removeFile(index)"
                      :fill="$theme['--red']"
                      viewport="0 0 20 20"
                      xlink="close-filled"/>
            </div>
            <Loader :key="item.id"
                    :params="{style: {width: '160px',height: '90px', margin: '15px'}}"
                    v-for="item in ready"/>
            <label class="popup-add-object__label popup-add-object__add-file"
                   for="objectFiles">
                <Icon viewport="0 0 47 47"
                      xlink="plus"/>
            </label>
        </Row>
    </div>
</template>

<script>
export default {
  name: 'AddFile',
  props: {
    params: {},
  },
  data() {
    return {
      files: [],
      ready: [],
      previews: [],
    };
  },
  methods: {
    addFiles() {
      this.files = this.$refs.objectFiles.files;
      this.files.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);
        let url = '/api/image';
        if (file.name.split('.')
          .pop() === 'mp3') {
          url = '/api/audios';
        }
        this.ready = [...this.ready, {}];
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        this.$http.post(url, formData, config)
          .then((resolve) => {
            this.previews = [...this.previews, `${resolve.data.data.src}`];
            this.ready.splice(0, 1);
          });
      });
    },
    removeFile(index) {
      this.previews.splice(index, 1);
    },
  },
  created() {
    this.previews = [...this.previews, ...this.params.uploadedImages];
  },
  watch: {
    files() {
      this.$emit('change-file', this.files);
    },
    previews() {
      this.$emit('change-preview', this.previews);
    },
  },
};
</script>
